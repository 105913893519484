<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="商品名称">
              <a-input
                v-model:value.trim="where.goodsName"
                placeholder="请输入商品名称"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="商品分类:">
              <a-select
                allow-clear
                placeholder="请选择商品分类"
                v-model:value="where.categoryId"
              >
                <a-select-option
                  v-for="item in sortList"
                  :key="item.categoryId"
                  :value="item.categoryId"
                >
                  {{ item.categoryName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="商品上下架:">
              <a-select
                allow-clear
                placeholder="请选择商品上下架"
                v-model:value="where.goodsStatus"
              >
                <a-select-option
                  v-for="item in goodsStatus"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="id"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
        @change="onTableChange"
      >
        <template #toolbar>
          <a-space>
            <div>
              <a-radio-group v-model:value="radioValue" @change="radioChange">
                <a-radio-button value="all">全部</a-radio-button>
                <a-radio-button value="onSale">出售中</a-radio-button>
                <a-radio-button value="offSale">已下架</a-radio-button>
                <a-radio-button value="soldOut">已售完</a-radio-button>
              </a-radio-group>
            </div>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>创建商品</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #img="{ record }">
          <img :src="record.goodsMainPic" width="50" height="50" />
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">编辑</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项吗？" @confirm="remove(record)">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑 -->
    <!-- <RecommendEdit v-model:visible="showEdit" :data="current" @done="reload" /> -->
    <!-- 详情弹窗 -->
    <QuestionInfoDetail v-model:visible="showDetail" :data="current || {}" />
  </div>
</template>

<script>
// import * as pointsGoodsApi from '@/api/jyyw/pointsGoods'
// import * as pointsCategoryApi from '@/api/jyyw/pointsCategory'
// import * as dictDataApi from '@/api/sys/dictData'
// import QuestionInfoDetail from './product-detail.vue'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
export default {
  name: 'navigationIndex',
  components: {
    PlusOutlined,
    DeleteOutlined
    // QuestionInfoDetail
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          title: '商品名称',
          dataIndex: 'goodsName'
        },
        {
          title: '主图片',
          dataIndex: 'goodsMainPic',
          sorter: true,
          slots: { customRender: 'img' }
        },
        {
          title: '商品分类',
          dataIndex: 'categoryName'
        },
        {
          title: '商品积分',
          dataIndex: 'goodsPrice'
        },
        {
          title: '商品特点',
          dataIndex: 'sellingPoint',
          width: '300px',
          sorter: true
        },
        {
          title: '商品数量',
          dataIndex: 'stockNumber',
          sorter: true
        },
        {
          title: '状态',
          dataIndex: 'goodsStatusName',
          sorter: true
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: '150px',
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格排序条件
      order: {},
      // 表格选中数据
      selection: [],
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      // 是否显示用户导入弹窗
      showImport: false,
      categoryList: [],
      // 商品名称list
      sortList: [],
      // 状态字典项
      goodsStatus: [],
      dataSource: [
        {
          key: '1',
          goodsName: '胡彦斌',
          goodsPrice: 32,
          sellingPoint: '西湖区湖底公园1号'
        },
        {
          key: '2',
          goodsName: '胡彦祖',
          goodsPrice: 42,
          sellingPoint: '西湖区湖底公园1号'
        }
      ],
      radioValue: 'all'
    }
  },
  created() {
    // this.querysortList()
    // this.queryDataRoles()
  },
  methods: {
    radioChange(e) {
      console.log(e.target.value)
    },
    // datasource(option, callback) {
    //   pointsGoodsApi
    //     .page({
    //       ...this.where,
    //       ...this.order,
    //       page: option.page,
    //       limit: option.limit
    //     })
    //     .then((res) => {
    //       if (res.code === 0) {
    //         res.data.map((item) => {
    //           if (item.goodsStatus === 0) {
    //             item.goodsStatusName = '下架'
    //           } else if (item.goodsStatus === 1) {
    //             item.goodsStatusName = '上架'
    //           }
    //         })

    //         callback(res.data, res.count)
    //       } else {
    //         callback(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       callback(e.message)
    //     })
    // },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = {}
      }
      // this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    // remove(row) {
    //   const hide = this.$message.loading('请求中..', 0)
    //   pointsGoodsApi
    //     .deleteById(row.goodsId)
    //     .then((res) => {
    //       hide()
    //       if (res.code === 0) {
    //         this.$message.success(res.msg)
    //         this.reload()
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       hide()
    //       this.$message.error(e.message)
    //     })
    // },
    /* 批量删除 */
    // removeBatch() {
    //   if (!this.selection.length) {
    //     this.$message.error('请至少选择一条数据')
    //     return
    //   }
    //   this.$confirm({
    //     title: '提示',
    //     content: '确定要删除选中的项吗?',
    //     maskClosable: true,
    //     onOk: () => {
    //       const hide = this.$message.loading('请求中..', 0)
    //       const data = { data: this.selection.map((d) => d.goodsId) }
    //       pointsGoodsApi
    //         .deleteBatch(data)
    //         .then((res) => {
    //           hide()
    //           if (res.code === 0) {
    //             this.$message.success(res.msg)
    //             this.reload()
    //           } else {
    //             this.$message.error(res.msg)
    //           }
    //         })
    //         .catch((e) => {
    //           hide()
    //           this.$message.error(e.message)
    //         })
    //     }
    //   })
    // },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.$router.push({
        path: './indexEdit'
      })
      //   if (!row) {
      //     this.$router.push({
      //       path: './indexEdit'
      //     })
      //     return
      //   }
      //   this.$router.push({
      //     path: './indexEdit',
      //     query: { goodsId: row.goodsId }
      //   })
    },
    /* 详情 */
    openDetail(row) {
      console.log('row', row)
      this.current = row
      this.showDetail = true
    }
    // 获取商品名称List
    // querysortList() {
    //   pointsCategoryApi
    //     .all()
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.sortList = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // },
    // 是否显示
    // queryDataRoles() {
    //   dictDataApi
    //     .querydictdata('shelfs')
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.goodsStatus = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // }
  }
}
</script>

<style scoped>
</style>
